import { Component } from "react";
import { connect } from "react-redux";


class LandingPage extends Component {
  constructor() {
    super();
    this.state = {};
  }
  componentDidMount(){
    this.props.history.push('/telstra/welcome')
  }

  render() {
    return (
      <>
        
      </>
    );
  }
}

export default connect()(LandingPage);
