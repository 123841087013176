import React, { useState } from 'react';
import {
    NexusFormField,
    NexusLabel,
    NexusInput,
    NexusSelect,
    NexusOption,
    NexusErrorMessage
} from "@nexus/react";
import Header from '../sharedComponents/Header';
import '../style/ElectronicEquipment.scss';
import { Clear } from '../../../assets/icons';
import { SUBMIT_ELECTRONIC_EQUIPMENTS } from "../../../constants/ActionTypes";
import { useDispatch,useSelector } from "react-redux";

const ElectronicEquipment = (props) => {

    const dispatch = useDispatch();
    const {quote} = useSelector(state => state);

    const [state, setState] = useState({
        restoringDataCost: '',
        costOfWorking: ''
    });
    const equipments = [
        "Computers",
        "Communication Systems",
        "Electronic Scales",
        "Office Equipment"
    ]
    const [equipmentTemplate, setEquipmentTemplate] = useState({
        id: 0,
        name: "",
        description: "",
        yearOfPurchase: "",
        insureAmoount: ""
    });
    const [equimentsList, setEquipments] = useState([]);
    const [activeEdit, setActiveEdit] = useState(false);
    const [isSubmitted, setSubmitted] = useState(false);
    const [isTemplateSubmitted, setTempleteSubmited] = useState(false);
    const {  history } = props;
    const addEquipment = () => {
        if (activeEdit) {
            setTempleteSubmited(true);
            if(equipmentTemplate.name && equipmentTemplate.yearOfPurchase && equipmentTemplate.insureAmoount && equipmentTemplate.description){
                setEquipments([...equimentsList, equipmentTemplate]);
                setActiveEdit(false);
                setEquipmentTemplate({
                    id: equimentsList.length,
                    name: "",
                    description: "",
                    yearOfPurchase: "",
                    insureAmoount: ""
                });
                setTempleteSubmited(false);
            }
        } else {
            setActiveEdit(true);
        }
    }

    const deleteItem = (item) => {
        setEquipments(equimentsList.filter((equipment) => equipment !== item))
    }

    const submitData = () => {
        setActiveEdit(false);
        setSubmitted(true);
        if(state.costOfWorking && state.restoringDataCost && equimentsList.length>0){
            dispatch({
                type: SUBMIT_ELECTRONIC_EQUIPMENTS,
                payload: { ...state, equimentsList }
            });
        }
        // history.push("cyber-liability");
        history.push(quote.selectedCoverage?.coverages[quote.selectedCoverage?.coverages.indexOf('electronic-equipment')+1]);
    }

    return (
        <div className='container telstra-container electronic-equipment-screen  d-flex flex-column '>
            <Header />
            <div className='content-wrapper d-flex flex-column flex-fill'>
                <div className='page-title'>Electronic Equipment Coverage Questions</div>
                <div className='form-wrapper'>
                    {equimentsList.length > 0 && <div className="selected-items">
                        {equimentsList.map((data, index) =>
                            <div key={index.toString()} className={'d-inline-flex selected-equipment'}>
                                <label className='selected-item'>Item {index + 1}: {data.name}</label>
                                <img src={Clear} className='clear-icon' alt="clear-icon" onClick={() => { deleteItem(data) }} />
                            </div>
                        )}
                    </div>}
                    {activeEdit && <div>
                        <div className='item-title'>Item {equimentsList.length + 1}</div>
                        <div>
                            <div className='select-wrapper'>
                                <label className='select-label'>Please select the type of electronic equipment you would like to add</label>
                                <NexusFormField>
                                    <NexusLabel>Please select...</NexusLabel>
                                    <NexusSelect
                                        value={equipmentTemplate.name}
                                        name="equipment name"
                                        placeholder="Please select..."
                                        onInput={(event) => setEquipmentTemplate({ ...equipmentTemplate, name: event.target.value })}>
                                        {equipments.map((data) =>
                                            <option key={data} value={data}>{data}</option>
                                        )}
                                    </NexusSelect>
                                    {isTemplateSubmitted && !equipmentTemplate.name && <NexusErrorMessage>Please select the equipment.</NexusErrorMessage>}
                                </NexusFormField>
                            </div>
                            <div className='select-wrapper'>
                                <label className='select-label'>Please provide a brief description of the equipment you are adding</label>
                                <NexusFormField
                                    className="app-input feild-spacing"
                                >
                                    <NexusLabel>Please enter the description</NexusLabel>
                                    <NexusInput
                                        type="text"
                                        value={equipmentTemplate.description}
                                        onInput={(event) => setEquipmentTemplate({ ...equipmentTemplate, description: event.target.value })}
                                    />
                                    {isTemplateSubmitted && !equipmentTemplate.description && <NexusErrorMessage>Please enter the description.</NexusErrorMessage>}
                                </NexusFormField>
                            </div>
                            <div className='select-wrapper'>
                                <label className='select-label'>Please input the year in which you purchased this equipment</label>
                                <NexusFormField
                                    className="app-input feild-spacing"
                                >
                                    <NexusLabel>Select Year</NexusLabel>
                                    <NexusInput
                                        type="number"
                                        value={equipmentTemplate.yearOfPurchase}
                                        onInput={(event) => setEquipmentTemplate({ ...equipmentTemplate, yearOfPurchase: event.target.value })}
                                    />
                                    {isTemplateSubmitted && !equipmentTemplate.yearOfPurchase && <NexusErrorMessage>Please enter the year of purchase.</NexusErrorMessage>}
                                </NexusFormField>
                            </div>
                            <div className='select-wrapper'>
                                <label className='select-label'>Please input the value of the equipment that you want to insure</label>
                                <NexusFormField
                                    className="app-input feild-spacing"
                                >
                                    <NexusLabel>Enter the amount</NexusLabel>
                                    <NexusInput
                                        type="number"
                                        value={equipmentTemplate.insureAmoount}
                                        onInput={(event) => setEquipmentTemplate({ ...equipmentTemplate, insureAmoount: event.target.value })}
                                    />
                                      {isTemplateSubmitted && !equipmentTemplate.insureAmoount && <NexusErrorMessage>Please enter the insured amount.</NexusErrorMessage>}
                                </NexusFormField>
                            </div>
                        </div>
                    </div>}
                    <button type="button" className='app-button add-equipment-btn' onClick={() => { addEquipment() }}>
                        {activeEdit ? '+ Confirm Equipment' : '+ Add Equipment'}
                    </button>
                    {isSubmitted && equimentsList.length===0 && !activeEdit && <NexusErrorMessage className='final-error'>Please add at least one equipment.</NexusErrorMessage>}
                    <div>
                        <div className='select-wrapper'>
                            <label className='select-label'>Please input the Restoring Data cost</label>
                            <NexusFormField
                                className="app-input feild-spacing"
                            >
                                <NexusLabel>Enter the amount</NexusLabel>
                                <NexusInput
                                    type="number"
                                    value={state.restoringDataCost}
                                    onInput={(event) => setState({ ...state, restoringDataCost: event.target.value })}
                                />
                                {isSubmitted && !state.restoringDataCost && <NexusErrorMessage>Please enter the restoring data cost.</NexusErrorMessage>}
                            </NexusFormField>
                        </div>
                        <div className='select-wrapper'>
                            <label className='select-label'>Please input the Cost of Working</label>
                            <NexusFormField
                                className="app-input feild-spacing"
                            >
                                <NexusLabel>Enter the amount</NexusLabel>
                                <NexusInput
                                    type="number"
                                    value={state.costOfWorking}
                                    onInput={(event) => setState({ ...state, costOfWorking: event.target.value })}
                                />
                                {isSubmitted && !state.costOfWorking && <NexusErrorMessage>Please enter cost of working.</NexusErrorMessage>}
                            </NexusFormField>
                        </div>
                    </div>
                </div>
                
                <button type="button" className='app-button submit-button' onClick={() => { submitData() }}>
                    CONTINUE
                </button>
            </div>
        </div>
    );
};

export default ElectronicEquipment;
