import React, { useState } from "react";
import {
  NexusFormField,
  NexusInput,
  NexusRadio,
  NexusErrorMessage,
} from "@nexus/react";
import moment from "moment";
import Header from "../sharedComponents/Header";
import { connect } from "react-redux";
import "../style/Payment.scss";
import { CreditCard, Paypal } from "../../../assets/icons";
import Service from "../sharedComponents/Service";
import { COMMERCIAL_SME_POLICY_RESPONSE } from "../../../constants/ActionTypes";
import { useDispatch } from "react-redux";

const Payment = (props) => {
  const service = new Service();
  const dispatch = useDispatch();

  const [state, setState] = useState({
    paymentMethod: "card",
    cardNumber: "",
    cvc: "",
    name: "",
    expiry: "",
  });

  const [isSubmitted, setSubmitted] = useState(false);

  const setCardNumber = (value) => {
    let formattedCardNumber = value.replace(/[^\d]/g, "");
    formattedCardNumber = formattedCardNumber.substring(0, 16);
    // Split the card number in groups of 4
    let cardNumberSections = formattedCardNumber.match(/\d{1,4}/g);
    if (cardNumberSections !== null) {
      formattedCardNumber = cardNumberSections.join("-");
    }
    setState({ ...state, cardNumber: formattedCardNumber });
  };

  const setExpiryDate = (value) => {
    let formattedExpiryDate = value.replace(/[^\d]/g, "");
    formattedExpiryDate = formattedExpiryDate.substring(0, 4);
    // Split the expiry Date in groups of 2
    let expiryDateSections = formattedExpiryDate.match(/\d{1,2}/g);
    if (expiryDateSections !== null) {
      formattedExpiryDate = expiryDateSections.join("/");
    }
    setState({ ...state, expiry: formattedExpiryDate });
  };

  const roundOffThisNumber = (num) => {
    return Math.round(num * 100) / 100;
  };

  const payAmount = () => {
    const {
      publicLiability,
      businessInterruption,
      electronicEquipment,
      employeeDishonesty,
      cyberLiability,
      businessInfo,
      insured,
      selectedCoverage,
      commercialSMESubmissionResponse,
      quoteSummary,
      dispatch,
    } = props;

    const electronicEquipmentList = [];
    if (electronicEquipment && electronicEquipment.equimentsList) {
      electronicEquipment.equimentsList.forEach((item) => {
        electronicEquipmentList.push({
          insuredDescriptionElectronicE: item.description,
          insuredItemElectronicE: item.name,
          insuredSumInsuredElectronicE: parseInt(item.insureAmoount),
          insuredYearPurchasedElectronicE: parseInt(item.yearOfPurchase),
        });
      });
    }

    setSubmitted(true);
    if (state.cardNumber && state.cvc && state.expiry && state.name) {
      const policySDate = moment(selectedCoverage.policyStartDate).format(
        "YYYY-MM-DDTHH:mm:ss[Z]"
      );
      const policyEDate = moment(
        commercialSMESubmissionResponse.policyEndDate
      ).format("YYYY-MM-DDTHH:mm:ss[Z]");
      const currentDate = moment(new Date()).format("YYYY-MM-DDTHH:mm:ss[Z]");
      const reqBody = {
        policyEndDate: policyEDate || currentDate,
        premiumTotal: commercialSMESubmissionResponse.premiumTotal,
        premiumTotalMonthly:
          commercialSMESubmissionResponse.premiumTotalMonthly,
        policyStartDate: policySDate || currentDate,
        insuredBusinessType: "Direct Seller of Mobile Phones",
        insuredNumberEmployees: parseInt(businessInfo.noOfEnmployees),
        insuredAnnualRevenue: parseInt(businessInfo.estimatedAnnualRevenue),
        insuredAddressLine1: businessInfo.businessAddress,
        insuredCity: businessInfo.businessCity,
        insuredZIP: businessInfo.businessPostalCode,
        insuredCountry: commercialSMESubmissionResponse.insuredCountry || "AUS",
        insuredEmail: insured.email || "claudia.ocampo.gil@ey.com",
        insuredTitle: commercialSMESubmissionResponse.insuredTitle || "Mr",
        insuredFirstName: insured.firstName,
        insuredLastName: insured.lastName,
        insuredPhone: insured.phone,
        insuredAUSResident: insured.isAustralian,
        insuredBillPlanType: quoteSummary.insuredBillPlanType || "Monthly",
        insuredPublicLiabilityCover: selectedCoverage?.publicLiability || "Yes",
        insuredBusinessInterruptionCover:
          selectedCoverage?.coverage?.businessInterruption || "Yes",
        insuredElectronicEquipmentCover:
          selectedCoverage.electronicEquipment || "Yes",
        insuredCyberLiabilityCover: selectedCoverage.cyberLiability || "No",
        insuredEmployeeDishonestyCover:
          selectedCoverage.employeeDishonesty || "Yes",
        insuredLevelPublicLiability: parseInt(publicLiability?.coverageAmount),
        insuredLandlordPublicLiability: publicLiability?.PublicLiability,
        insuredContractPublicLiability: publicLiability?.workForceStaffing,
        insuredCostWorkingBI: parseInt(
          businessInterruption?.additionalIncreasedCost
        ),
        insuredClaimsCostBI: parseInt(
          businessInterruption?.claimsPreparationsCost
        ),
        insuredOutstandingBI: parseInt(
          businessInterruption?.outstandingAccountsReceivable
        ),
        insuredIndemnityPeriodBI: businessInterruption?.indemnityPeriod,

        insuredSumInsuredEmployeeD: parseInt(
          employeeDishonesty?.coverageAmount
        ),
        insuredNumberEmployeesD: parseInt(
          employeeDishonesty?.workForceStaffing
        ),
        insuredCyberLiabilityLevel: parseInt(cyberLiability?.coverageAmount),
        insuredCyberClaims: commercialSMESubmissionResponse.insuredCyberClaims,
        insuredCyberOperatingSystems: cyberLiability?.OSWithAntiVirus,
        insuredCyberPassword: cyberLiability?.deviceProtected,
        insuredCyberFactorAuthentication:
          cyberLiability?.multiFactorAuthentication,
        insuredCyberRevenue: cyberLiability?.revenueDerivedFrom,
        insuredCyberOutsource: cyberLiability?.outsourceFromNetwork,
        insuredCyberFraud: cyberLiability?.coverForSocialEngineering,
        insuredCyberFinancialTransactions:
          cyberLiability?.numberOfFinancialTransactions,

        insuredRestoringCostElectronicE: parseInt(
          electronicEquipment?.restoringDataCost
        ),
        insuredCostWorkingElectronicE: parseInt(
          electronicEquipment?.costOfWorking
        ),
        insuredElectronicEquipmentItems: electronicEquipmentList,
      };

      service
        .commercialSMEPolicy(reqBody)
        .then((res) => res.json())
        .then((data) => {
          if (data.data) {
            dispatch({
              type: COMMERCIAL_SME_POLICY_RESPONSE,
              payload: data.data,
            });
            redirectMe("policysuccess");
          } else if (data.error) {
          }
        })
        .catch((err) => {});
      // remove this
      redirectMe("policysuccess");
      // till here
    }

    // remove this
    // dispatch({
    //   type: COMMERCIAL_SME_POLICY_RESPONSE,
    //   payload: data.data,
    // });
    // redirectMe("policysuccess");
    // till here
  };

  const redirectMe = (path) => {
    props.history.push(path);
  };

  return (
    <div className="container telstra-container payment-screen">
      <Header />
      <div className="content-wrapper">
        <div className="page-title feild-spacing">
          How would you like to pay?
        </div>
        <div className="d-flex justify-content-center">
              <NexusRadio
                name="paymentMethod"
                value="card"
                checked={state.paymentMethod === "card"}
                onInput={(event) =>
                  setState({ ...state, paymentMethod: event.target.value })
                }
              >
                <img src={CreditCard} className="credit-card" alt="card" />
              </NexusRadio>
              <NexusRadio
                name="paymentMethod"
                value="paypal"
                checked={state.paymentMethod === "paypal"}
                onInput={(event) =>
                  setState({ ...state, paymentMethod: event.target.value })
                }
              >
                <img src={Paypal} className="paypal" alt="paypal" />
              </NexusRadio>
        </div>
        <div className="credit-card-container">
          <div className="row">
            <div className="col">
              <label className="card-label">Card number</label>
              <NexusFormField className="card-input">
                <NexusInput
                  hideClearButton={true}
                  type="tel"
                  placeholder="XXXX-XXXX-XXXX-XXXX"
                  value={state.cardNumber}
                  onInput={(event) => setCardNumber(event.target.value)}
                  required
                />
              </NexusFormField>
            </div>
            <div className="col col-right">
              <label className="card-label">Expires</label>
              <NexusFormField className="card-input">
                <NexusInput
                  hideClearButton={true}
                  placeholder="MM/YY"
                  type="tel"
                  value={state.expiry}
                  onInput={(event) => setExpiryDate(event.target.value)}
                  required
                />
              </NexusFormField>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <label className="card-label">Name</label>
              <NexusFormField className="card-input">
                <NexusInput
                  hideClearButton={true}
                  placeholder="XYZ"
                  value={state.name}
                  onInput={(event) =>
                    setState({ ...state, name: event.target.value })
                  }
                  required
                />
              </NexusFormField>
            </div>
            <div className="col col-right">
              <label className="card-label">CVC</label>
              <NexusFormField className="card-input">
                <NexusInput
                  maxLength={3}
                  hideClearButton={true}
                  placeholder="000"
                  value={state.cvc}
                  onInput={(event) =>
                    setState({ ...state, cvc: event.target.value })
                  }
                  required
                />
              </NexusFormField>
            </div>
          </div>
        </div>
        {isSubmitted &&
          (!state.cardNumber || !state.cvc || !state.expiry || !state.name) && (
            <NexusErrorMessage>
              Please fill all the required fields.
            </NexusErrorMessage>
          )}
        <button
          type="button"
          className="app-button"
          onClick={() => {
            payAmount();
          }}
        >
          PAY
        </button>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { quote } = state;
  return {
    publicLiability: quote.publicLiability,
    businessInterruption: quote.businessInterruption,
    electronicEquipment: quote.electronicEquipment,
    employeeDishonesty: quote.employeeDishonesty,
    cyberLiability: quote.cyberLiability,
    businessInfo: quote.businessInfo,
    insured: quote.insured,
    selectedCoverage: quote.selectedCoverage,
    commercialSMESubmissionResponse: quote.commercialSMESubmissionResponse,
    quoteSummary: quote.quoteSummary,
  };
};

export default connect(mapStateToProps)(Payment);
