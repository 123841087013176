import { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";
import Header from "../sharedComponents/Header";
import "../style/CoverageSummary.scss";
import penIcon from "../../../assets/icons/pen.png";
import Service from "../sharedComponents/Service";
import { COMMERCIAL_SME_SUBMISSION_RESPONSE } from "../../../constants/ActionTypes";
import Loader from "../sharedComponents/Loader";

const service = new Service();
class CoverageSummary extends Component {
  constructor() {
    super();
    this.state = {
      percentageLoadingSMESubmission: false,
    };
  }
  doneLoading() {
    this.setState({ percentageLoadingSMESubmission: false });
  }
  stopLoader() {
    this.loader.loadingCompleted();
    this.setState({ percentageLoadingSMESubmission: false });
  }
  submitDetails = () => {
    const {
      publicLiability,
      businessInterruption,
      electronicEquipment,
      employeeDishonesty,
      cyberLiability,
      businessInfo,
      insured,
      selectedCoverage,
      dispatch,
    } = this.props;
    const policyDate = moment(selectedCoverage.policyStartDate).format(
      "YYYY-MM-DDTHH:mm:ss[Z]"
    );
    const currentDate = moment(new Date()).format("YYYY-MM-DDTHH:mm:ss[Z]");

    const electronicEquipmentList = [];
    if (electronicEquipment && electronicEquipment.equimentsList) {
      electronicEquipment.equimentsList.forEach((item) => {
        electronicEquipmentList.push({
          insuredDescriptionElectronicE: item.description,
          insuredItemElectronicE: item.name,
          insuredSumInsuredElectronicE: parseInt(item.insureAmoount),
          insuredYearPurchasedElectronicE: parseInt(item.yearOfPurchase),
          position: parseInt(item.id),
        });
      });
    }

    const reqBody = {
      insuredBillPlanType: "Monthly", // hardcode
      insuredBusinessType: "Direct seller of mobile phones", // hardcode
      insuredCountry: "AUS", // hardcode to USA
      insuredCyberClaims: "No", // hardcode to No
      insuredTitle: "Mr", // Check if mand.

      insuredPublicLiabilityCover: selectedCoverage?.coverage?.publicLiability || "No",
      // insuredPublicLiabilityCover: publicLiability.coverageAmount,
      insuredAUSResident: insured.isAustralian,
      insuredAddressLine1: businessInfo.businessAddress,
      insuredAddressLine2: businessInfo.businessAddress2,
      insuredAnnualRevenue: parseInt(businessInfo.estimatedAnnualRevenue),
      insuredBusinessInterruptionCover:
        selectedCoverage?.coverage?.businessInterruption || "No",
      insuredCity: businessInfo.businessCity,
      insuredClaimsCostBI: parseInt(
        businessInterruption?.claimsPreparationsCost
      ),
      insuredContractPublicLiability: publicLiability?.workForceStaffing,
      insuredCostWorkingBI: parseInt(
        businessInterruption?.additionalIncreasedCost
      ),
      insuredCostWorkingElectronicE: parseInt(
        electronicEquipment?.costOfWorking
      ),
      insuredCyberFactorAuthentication:
        cyberLiability?.multiFactorAuthentication,
      insuredCyberFinancialTransactions:
        cyberLiability?.numberOfFinancialTransactions,
      insuredCyberFraud: cyberLiability?.coverForSocialEngineering,
      insuredCyberLiabilityCover: selectedCoverage?.coverage?.cyberLiability || "No",
      insuredCyberLiabilityLevel: parseInt(cyberLiability?.coverageAmount),
      insuredCyberOperatingSystems: cyberLiability?.OSWithAntiVirus,
      insuredCyberOutsource: cyberLiability?.outsourceFromNetwork,
      insuredCyberPassword: cyberLiability?.deviceProtected,
      insuredCyberRevenue: cyberLiability?.revenueDerivedFrom,
      insuredElectronicEquipmentCover:
      selectedCoverage?.coverage?.electronicEquipment || "No",
      insuredElectronicEquipmentItems: electronicEquipmentList,
      insuredEmail: insured.email,
      insuredEmployeeDishonestyCover:
      selectedCoverage?.coverage?.employeeDishonesty || "No",
      insuredFirstName: insured.firstName,
      insuredIndemnityPeriodBI: businessInterruption?.indemnityPeriod,
      insuredLandlordPublicLiability: publicLiability?.PublicLiability,
      insuredLastName: insured.lastName,
      insuredLevelPublicLiability: parseInt(publicLiability?.coverageAmount),
      insuredNumberEmployees: parseInt(businessInfo.noOfEnmployees),
      insuredNumberEmployeesD: parseInt(employeeDishonesty?.workForceStaffing),
      insuredOutstandingBI: parseInt(
        businessInterruption?.outstandingAccountsReceivable
      ),
      insuredPhone: insured.phone,
      insuredRestoringCostElectronicE: parseInt(
        electronicEquipment?.restoringDataCost
      ),
      insuredSumInsuredEmployeeD: parseInt(employeeDishonesty?.coverageAmount),
      insuredZIP: businessInfo.businessPostalCode,
      policyStartDate: selectedCoverage.policyStartDate
        ? policyDate
        : currentDate,
      // policyStartDate: selectedCoverage.policyStartDate
    };

    this.setState({
      percentageLoadingSMESubmission: true,
    });
    // remove this part
    setTimeout(() => {
      this.setState({
        percentageLoadingSMESubmission: false,
      });
      this.redirectMe("quotesummary");
    }, 3000);

    service
      .commercialSMESubmission(reqBody)
      .then((res) => res.json())
      .then((data) => {
        if (data.data) {
          dispatch({
            type: COMMERCIAL_SME_SUBMISSION_RESPONSE,
            payload: data.data,
          });
          // this.setState({
          //   percentageLoadingSMESubmission: false,
          // });
          // this.redirectMe("quotesummary");
        } else if (data.error) {
          // this.stopLoader();
        }
      })
      .catch((err) => {});

    // this.redirectMe("quotesummary");
  };

  redirectMe(path) {
    this.props.history.push(path);
  }

  render() {
    const {
      publicLiability,
      businessInterruption,
      electronicEquipment,
      employeeDishonesty,
      cyberLiability,
      selectedCoverage,
    } = this.props;
    const { coverages } = selectedCoverage;
    const { percentageLoadingSMESubmission } = this.state;
    return (
      <>
        <>
          <Header />
          {!percentageLoadingSMESubmission && (
            <div className="container telstra-container gx-4 pb-5">
              <div className="row">
                <div className="col-md-12 col-lg-3"> </div>
                <div className="col-md-12 col-lg-6">
                  <div className="row">
                    <div className="col-md-12">
                      <p className="page-title">
                        {" "}
                        Here’s a summary of your coverage selections{" "}
                      </p>
                    </div>

                    {coverages.indexOf("publicliability") !== -1 && (
                      <>
                        <div className="col-md-12 cs-section-space">
                          <div className="row">
                            <div className="col-md-12 headings">
                              <div className="cs-page-title">
                                Public Liability
                              </div>
                              <div>
                                <img
                                  src={penIcon}
                                  alt="Edit"
                                  className="edit-icon"
                                  onClick={() =>
                                    this.redirectMe("publicliability")
                                  }
                                />
                              </div>
                            </div>
                            <div className="col-md-12 mt-2">
                              <label className="cs-selected-item">
                                Coverage Amount:{" "}
                                {publicLiability?.coverageAmount}
                              </label>
                            </div>
                          </div>
                        </div>
                        <hr className="customized-hr" />
                      </>
                    )}

                    {coverages.indexOf("business-interruption") !== -1 && (
                      <>
                        <div className="col-md-12 cs-section-space">
                          <div className="row">
                            <div className="col-md-12 headings">
                              <div className="cs-page-title">
                                Business Interruption
                              </div>
                              <div>
                                <img
                                  src={penIcon}
                                  alt="Edit"
                                  className="edit-icon"
                                  onClick={() =>
                                    this.redirectMe("business-interruption")
                                  }
                                />
                              </div>
                            </div>
                            <div className="col-md-12">
                              <label className="cs-selected-item mt-4">
                                Increased Cost of Working:{" "}
                                {businessInterruption?.additionalIncreasedCost}
                              </label>
                              <label className="cs-selected-item mt-4">
                                Claims Prep Cost:{" "}
                                {businessInterruption?.claimsPreparationsCost}
                              </label>
                              <label className="cs-selected-item mt-4">
                                Outstanding Accounts Receivable:{" "}
                                {
                                  businessInterruption?.outstandingAccountsReceivable
                                }
                              </label>
                              <label className="cs-selected-item mt-4">
                                Indemnity Period:{" "}
                                {businessInterruption?.indemnityPeriod}
                              </label>
                            </div>
                          </div>
                        </div>
                        <hr className="customized-hr" />
                      </>
                    )}

                    {coverages.indexOf("electronic-equipment") !== -1 && (
                      <>
                        <div className="col-md-12 cs-section-space">
                          <div className="row">
                            <div className="col-md-12 headings">
                              <div className="cs-page-title">
                                Electronic Equipment
                              </div>
                              <div>
                                <img
                                  src={penIcon}
                                  alt="Edit"
                                  className="edit-icon"
                                  onClick={() =>
                                    this.redirectMe("electronic-equipment")
                                  }
                                />
                              </div>
                            </div>
                            <div className="col-md-12 mt-2">
                              <label className="cs-selected-item">
                                Restoring data cost:{" "}
                                {electronicEquipment?.restoringDataCost}
                              </label>
                              <label className="cs-selected-item mt-4">
                                Cost of working:{" "}
                                {electronicEquipment?.costOfWorking}
                              </label>
                            </div>
                          </div>
                          <div className="row mt-4">
                            <div className="col-md-12 headings">
                              <div className="cs-page-title cs-page-sub-title">
                                Covered equipment
                              </div>
                            </div>
                            <div className="col-md-12 mt-2">
                              {electronicEquipment?.equimentsList.map(
                                (element) => {
                                  return (
                                    <label className="cs-selected-item mt-4">
                                      item {element.id + 1}: {element.name}
                                    </label>
                                  );
                                }
                              )}
                            </div>
                          </div>
                        </div>{" "}
                        <hr className="customized-hr" />
                      </>
                    )}

                    {coverages.indexOf("employeedishonesty") !== -1 && (
                      <>
                        <div className="col-md-12 cs-section-space">
                          <div className="row">
                            <div className="col-md-12 headings">
                              <div className="cs-page-title">
                                Employee Dishonesty
                              </div>
                              <div>
                                <img
                                  src={penIcon}
                                  alt="Edit"
                                  className="edit-icon"
                                  onClick={() =>
                                    this.redirectMe("employeedishonesty")
                                  }
                                />
                              </div>
                            </div>
                            <div className="col-md-12 mt-2">
                              <label className="cs-selected-item">
                                Coverage Amount:{" "}
                                {employeeDishonesty?.coverageAmount}
                              </label>
                              <label className="cs-selected-item mt-4">
                                Number of Employees:{" "}
                                {employeeDishonesty?.workForceStaffing}
                              </label>
                            </div>
                          </div>
                        </div>
                        <hr className="customized-hr" />
                      </>
                    )}

                    {coverages.indexOf("cyber-liability") !== -1 && (
                      <>
                        <div className="col-md-12 cs-section-space">
                          <div className="row">
                            <div className="col-md-12 headings">
                              <div className="cs-page-title">
                                Cyber Liability
                              </div>
                              <div>
                                <img
                                  src={penIcon}
                                  alt="Edit"
                                  className="edit-icon"
                                  onClick={() =>
                                    this.redirectMe("cyber-liability")
                                  }
                                />
                              </div>
                            </div>
                            <div className="col-md-12 mt-2">
                              <label className="cs-selected-item">
                                Coverage Amount:{" "}
                                {cyberLiability?.coverageAmount}
                              </label>
                            </div>
                          </div>
                        </div>
                        <hr className="customized-hr" />
                      </>
                    )}
                  </div>

                  <div className="row gx-0">
                    <div className="col-md-12">
                      <button
                        type="button"
                        className="app-button"
                        onClick={() => this.submitDetails()}
                      >
                        CONTINUE
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {percentageLoadingSMESubmission && (
            <div className="container telstra-container gx-4 pb-5">
              <div className="row">
                <div className="col-md-12 col-lg-3"> </div>
                <div className="col-md-12 col-lg-6">
                  <div className="row">
                    <div className="col-md-12">
                      <p className="page-title" style={{ marginTop: "20px" }}>
                        {" "}
                        Give us a moment to build your quote{" "}
                      </p>
                    </div>
                    <div className="col-md-12 cs-text-center">
                      <Loader
                        ref={(instance) => {
                          this.loader = instance;
                        }}
                        doneLoading={() => this.doneLoading()}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { quote } = state;
  
  return {
    publicLiability: quote.publicLiability,
    businessInterruption: quote.businessInterruption,
    electronicEquipment: quote.electronicEquipment,
    employeeDishonesty: quote.employeeDishonesty,
    cyberLiability: quote.cyberLiability,
    businessInfo: quote.businessInfo,
    insured: quote.insured,
    selectedCoverage: quote.selectedCoverage,
  };
};

export default connect(mapStateToProps)(CoverageSummary);
