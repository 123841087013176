import React, { useEffect } from 'react';
import Header from '../sharedComponents/Header';
import '../style/PolicySuccess.scss';
import { Celebration } from '../../../assets/icons';
import Service from "../sharedComponents/Service";
import { useSelector } from "react-redux";

const PolicySuccess = (props) => {
    const service = new Service();

    const {quote} = useSelector(state => state);

    const submissionData = quote?.commercialSMESubmissionResponse;

    useEffect(() => {
        sendEmail();
    }, []);

    const sendSMS = () => {
        let params = {
            "body": `Thanks ${submissionData?.insuredFirstName} ${submissionData?.insuredLastName} for purchasing Commercial Business policy ${quote?.commercialSMEPolicyResponse?.policyNumber} from Telstra!`,
            "from": "13049062554",
            "to": quote.insured.phone
        }

        return new Promise((resolve) => {
            service
                .sendSMS(params)
                .then((res) => res.json())
                .then((data) => {
                    if (data.data) {
                        resolve(true);
                    } else if (data.error) {
                        resolve(false);
                    }
                })
                .catch((err) => {
                    resolve(false);
                });
        });
    }

    const sendEmail = () => {
        let params = {
            "subject": "Telstra Business Insurance",
            "body": `Thanks ${submissionData?.insuredFirstName} ${submissionData?.insuredLastName} for purchasing Commercial Business policy ${quote?.commercialSMEPolicyResponse?.policyNumber} from Telstra!`,
            "from": "nexusforinsurance@ey.com",
            "to": quote.insured.email
        }
        return new Promise((resolve) => {
            service
                .sendEmail(params)
                .then((res) => res.json())
                .then((data) => {
                    if (data.data) {
                        resolve(true);
                    } else if (data.error) {
                        resolve(false);
                    }
                })
                .catch((err) => {
                    resolve(false);
                });
        });
    }

    return (
        <div className='container telstra-container policy-success-screen'>
            <Header isBackButton={false} />
            <div className='content-wrapper d-flex flex-column align-items-center'>
                <div className='page-title text-center'>Your business is covered! We sent your policy documents to the email address on file</div>
                <img src={Celebration} className='celebration-img' />
                <div className='sub-text'>
                    If you would also like receive the policy documents and other details through SMS, please click below!
                </div>
                <button type="button" className='app-button' onClick={() => { sendSMS() }}>
                    SEND DETAILS VIA SMS
                </button>
            </div >
        </div>
    );
};

export default PolicySuccess;
