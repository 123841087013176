import {
  RESET_ALL_STATES,
  SUBMIT_INUSRED_DETAILS,
  SUBMIT_BUSINESS_INFO,
  SUBMIT_COVERAGE_DETAILS,
  SUBMIT_BUSINESS_INTERRUPTION,
  SUBMIT_ELECTRONIC_EQUIPMENTS,
  SUBMIT_CYBER_LIABILITY,
  SUBMIT_EMPLOYEE_DISHONESTY,
  SUBMIT_VALIDATED_ADDRESS,
  COMMERCIAL_SME_SUBMISSION_RESPONSE,
  COMMERCIAL_SME_POLICY_RESPONSE,
  SUBMIT_QUOTE_SUMMERY_DETAILS,
  SUBMIT_PUBLIC_LIABILITY,
} from "../constants/ActionTypes";

const initialState = {
  insured: null,
  businessInfo: null,
  selectedCoverage: null,
  publicLiability: null,
  businessInterruption: null,
  electronicEquipment: null,
  cyberLiability: null,
  employeeDishonesty: null,
  quoteSummary: null,
  validatedAddress: null,
  commercialSMESubmissionResponse: null,
  commercialSMEPolicyResponse: null,
};
const defaultState = { ...initialState };

const resetAllUserInfoStates = () => ({ ...defaultState });

const address = (state = initialState, action) => {
  switch (action.type) {
    case RESET_ALL_STATES:
      return resetAllUserInfoStates();
    case SUBMIT_PUBLIC_LIABILITY:
      return {
        ...state,
        publicLiability: action.payload,
      };
    case SUBMIT_INUSRED_DETAILS:
      return {
        ...state,
        insured: action.payload,
      };
    case SUBMIT_BUSINESS_INFO:
      return {
        ...state,
        businessInfo: action.payload,
      };
    case SUBMIT_COVERAGE_DETAILS:
      return {
        ...state,
        selectedCoverage: action.payload,
      };
    case SUBMIT_BUSINESS_INTERRUPTION:
      return {
        ...state,
        businessInterruption: action.payload,
      };
    case SUBMIT_CYBER_LIABILITY:
      return {
        ...state,
        cyberLiability: action.payload,
      };
    case SUBMIT_ELECTRONIC_EQUIPMENTS:
      return {
        ...state,
        electronicEquipment: action.payload,
      };
    case SUBMIT_EMPLOYEE_DISHONESTY:
      return {
        ...state,
        employeeDishonesty: action.payload,
      };
    case SUBMIT_VALIDATED_ADDRESS:
      return {
        ...state,
        validatedAddress: action.payload,
      };
    case COMMERCIAL_SME_SUBMISSION_RESPONSE:
      return {
        ...state,
        commercialSMESubmissionResponse: action.payload,
      };
    case COMMERCIAL_SME_POLICY_RESPONSE:
      return {
        ...state,
        commercialSMEPolicyResponse: action.payload,
      };
    case SUBMIT_QUOTE_SUMMERY_DETAILS:
      return {
        ...state,
        quoteSummary: action.payload,
      };
    default:
      return state;
  }
};

export default address;
