class Service {
  token = localStorage.getItem("token");

  // token = store.getState().auth.data;
  API_URL = process.env.REACT_APP_BASE_API_URL;
  API_KEY = process.env.REACT_APP_BASE_API_KEY;
  API_ENV = process.env.REACT_APP_BASE_ENV;

  requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "access-control-allow-header": "*",
      Authorization: "Bearer " + localStorage.getItem("token"),
      apikey: this.API_KEY,
    },
    body: "",
  };

  /* AUTH TOKEN API  */
  getAuthToken() {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        client_id: process.env.REACT_APP_MISC_TOKEN_CLIENT_ID,
        client_secret: process.env.REACT_APP_MISC_TOKEN_CLIENT_SECRET,
        grant_type: process.env.REACT_APP_MISC_TOKEN_GRANT_TYPE,
        resource: process.env.REACT_APP_MISC_TOKEN_RESOURCE,
      }),
    };
    return fetch(
      "https://nexusforinsurance-"+this.API_ENV+".sbp.eyclienthub.com/ni/miscellaneous/app/keyvault/token",
      requestOptions
    );
  }

  addressValidation(data) {
    // this.requestOptions.headers["X-InsurCode"] = "PRECISELY";
    let body = JSON.stringify(data);
    this.requestOptions.body = body;
    return fetch(
      this.API_URL +
        "/ni/intladdress/validation/app/v1/validateinternationaladdress",
      this.requestOptions
    );
  }

  phoneNumberValidator(phoneNumber) {
    // delete this.requestOptions.headers["X-InsurCode"];
    let body = JSON.stringify({
      phoneNumber: phoneNumber,
    });
    this.requestOptions.body = body;
    return fetch(
      this.API_URL + "/ni/emailphone/app/v1/phonenumberverification",
      this.requestOptions
    );
  }

  emailValidator(email) {
    let body = JSON.stringify({
      email: email,
    });
    this.requestOptions.body = body;
    return fetch(
      this.API_URL + "/ni/emailphone/app/v1/emailverification",
      this.requestOptions
    );
  }

  sendEmail(data) {
    let body = JSON.stringify(data);
    this.requestOptions.body = body;
    return fetch(
      this.API_URL + "/ni/notification/app/v1/sendemail",
      this.requestOptions
    );
  }

  sendSMS(data) {
    let body = JSON.stringify(data);
    this.requestOptions.body = body;

    return fetch(
      this.API_URL + "/ni/notification/app/v1/sendsms",
      this.requestOptions
    );
  }

  /* Commercial SME Submission Domain Service */

  commercialSMESubmission(data) {
    let body = JSON.stringify(data);
    // this.requestOptions.headers['apikey'] = "vfdzXdoGsu19wcsOAMjIGS35XZiPmoA9";
    this.requestOptions.body = body;
    return fetch(
      this.API_URL + "/ni/commercialsme/submission/app/v1/create", // GET NEW END POINTS 
      this.requestOptions
    );
  }

  commercialSMEPolicy(data) {
    let body = JSON.stringify(data);
    this.requestOptions.body = body;
    return fetch(
      this.API_URL + "/ni/commercialsme/policy/app/v1/createbysubmission",
      this.requestOptions
    );
  }
}

export default Service;
