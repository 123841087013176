import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage  from "redux-persist/lib/storage";
import auth from "./auth";
import quote from "./quote";

const persistConfig ={
  key : 'root',
  storage,
}

const rootReducer = combineReducers({
  auth,
  quote,
});

export default persistReducer(persistConfig, rootReducer);
