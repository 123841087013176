import React from "react";
import "./style.scss";
// import { MotifFormField, MotifToggleSwitch } from '@ey-xd/motif-react';

const ToggleButton = (props) => {
  const onTrigger = (event, value) => {
    props.parentCallback(value);
    event.preventDefault();
  };

  return (
    <div className="toggle-button">
      <label className="switch">
        <input type="checkbox" />
        <span className="slider" >
          <span className={`${props.premiumType === 'Monthly' ? 'selected' : 'dummy'}`} onClick={(event) => onTrigger(event, 'Monthly')}>MONTHLY</span>
          <span className={`${props.premiumType === 'Annually' ? 'selected' : 'dummy'}`} onClick={(event) => onTrigger(event, 'Annually')}>
            ANNUALLY
          </span>
        </span>
      </label>
    </div>
  );
};

export default ToggleButton;
