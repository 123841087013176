import React, { Component } from "react";
import { connect } from "react-redux";
import Service from "../sharedComponents/Service";
import Header from '../sharedComponents/Header';
import '../style/Welcome.css';
import { ImageLandingPage } from '../../../assets/icons/';
import { RESET_ALL_STATES } from "../../../constants/ActionTypes";

class Welcome extends Component {
  service = new Service();

  componentDidMount(){
    localStorage.setItem("token", 'remove-token');
    this.tokenAPI();
  }

  tokenAPI() {
    this.service
      .getAuthToken()
      .then((res) => res.text())
      .then((data) => {
        localStorage.setItem("token", data);
        this.props.dispatch({
          type: RESET_ALL_STATES,
          payload: {},
        });
        
      })
      .catch((err) => console.log(err));
  };

  nextPage() {
    //this.props.history.push('quotesummary');
    this.props.history.push('business-info');
  }
  render() {
    return (
      <>
        <Header isBackButton={false}/>
        <div className="container telstra-container px-4 pb-5">
          <div className='row'>
            <div className="col-md-12 col-lg-3"> </div>
            <div className="col-md-12 col-lg-6">
              <div className='row text-center'>
                <p className="col-12 pageHeadingWel"> Cover designed to protect your business, tailored by Telstra </p>
              </div>

              <div className="row mt-4 mb-4">
                <div className="col-12 px-3">
                  <p className="pageDecriptionWel"> Get Business Insurance from the people who know your business best. Telstra Insurance policies are designed for Telstra franchisees, so you don’t need to worry about paying more for coverage you don’t need. </p>
                </div>
              </div>

              <div className='row gx-0'>
                <button type="button" className='app-button' onClick={() => this.nextPage()}>
                  + GET A QUOTE
                </button>
              </div>

              <div className='row mt-4 mb-4'>
                <button type="button" className="link-button">
                  Or Retrieve a Quote
                </button>
              </div>

              <div className='row mt-5'>
                <img src={ImageLandingPage} alt="">
                </img>
              </div>

              <div className='row mt-5 '>
                <span className="roundDotWelcome"> </span>
              </div>

              <div className='row mt-5 justify-content-end'>
                <div className="col-3  roundDotSmall">
                </div>
                <div className="col-3 roundDotSmall" style={{ backgroundColor: "white" }}>
                </div>
              </div>

            </div>
          </div>
        </div>

      </>
    );
  }

};

export default connect()(Welcome);