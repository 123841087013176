export const INDEMNITY_PERIOD = [
  {
    name: "6 months",
    key: "6 months",
    value: "6 months",
  },
  {
    name: "12 months",
    key: "12 months",
    value: "12 months",
  },
  {
    name: "18 months",
    key: "18 months",
    value: "18 months",
  },
  {
    name: "24 months",
    key: "24 months",
    value: "24 months",
  },
  {
    name: "36 months",
    key: "36 months",
    value: "36 months",
  },
];

export const CYBER_LIABILITY_COST = [
  {
    name: "50000",
    key: "50000",
    value: "50000",
  },
  {
    name: "100000",
    key: "100000",
    value: "100000",
  },
  {
    name: "250000",
    key: "250000",
    value: "250000",
  },
  {
    name: "500000",
    key: "500000",
    value: "500000",
  },
  {
    name: "1000000",
    key: "1000000",
    value: "1000000",
  },
  {
    name: "2000000",
    key: "2000000",
    value: "2000000",
  },
];

export const numberWithCommas = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}