import React from "react";
import {NexusIcon, NexusHeaderLogo} from '@nexus/react';
import Logo from '@nexus/core/dist/assets/images/nexus-logo.svg';
import './style.scss';
import { Menu, IconBack } from '../../../../assets/icons/';
import { useHistory } from "react-router-dom";

const Header = ({onPress, isBackButton=true}) => {
    const history = useHistory();
    const goBack = ()=>{
      if(onPress){
        onPress();
      }else{
        history.goBack();
      }
    }
    const redirectMe = () => {
      history.push('/telstra/welcome');
    }
    return (
        <div className='app-header d-flex flex-row align-items-center'>
            <img src={Menu} className='header-menu' alt="Menu" />
            <NexusHeaderLogo>
            <NexusIcon data-testid='nexus-header-logo' src={Logo} /> <span className='nexus-visually-hidden'>Home</span>
            </NexusHeaderLogo>
            <div className='buttons-container'>
              {isBackButton && <img src={IconBack} className='header-back' alt="IconBack" onClick={()=> { goBack() }}/>}
            </div>
        </div>
    );
}


export default Header;