import React, { useState, useEffect } from 'react';
import { NexusFormField, NexusLabel, NexusInput, NexusRadio, NexusErrorMessage } from '@nexus/react';
import Header from '../sharedComponents/Header';
import '../style/CoverageSelection.scss';
import { useDispatch } from 'react-redux';
import {
  SUBMIT_COVERAGE_DETAILS,
  SUBMIT_PUBLIC_LIABILITY,
  SUBMIT_BUSINESS_INTERRUPTION,
  SUBMIT_ELECTRONIC_EQUIPMENTS,
  SUBMIT_CYBER_LIABILITY,
  SUBMIT_EMPLOYEE_DISHONESTY,
  COMMERCIAL_SME_SUBMISSION_RESPONSE,
  COMMERCIAL_SME_POLICY_RESPONSE,
} from '../../../constants/ActionTypes';

import { Business, LiveHelp, GreyTick } from '../../../assets/icons';

const CoverageSelection = (props) => {
  const { history } = props;

  const [state, setState] = useState({
    policyStartDate: '',
    coverage: {
      publicLiability: null,
      businessInterruption: null,
      electronicEquipment: null,
      cyberLiability: null,
      employeeDishonesty: null,
    },
  });
  const dispatch = useDispatch();
  // reset redux state for optional screens
  useEffect(() => {
    dispatch({
      type: SUBMIT_PUBLIC_LIABILITY,
      payload: null,
    });
    dispatch({
      type: SUBMIT_BUSINESS_INTERRUPTION,
      payload: null,
    });
    dispatch({
      type: SUBMIT_ELECTRONIC_EQUIPMENTS,
      payload: null,
    });
    dispatch({
      type: SUBMIT_CYBER_LIABILITY,
      payload: null,
    });
    dispatch({
      type: SUBMIT_EMPLOYEE_DISHONESTY,
      payload: null,
    });
    dispatch({
      type: COMMERCIAL_SME_SUBMISSION_RESPONSE,
      payload: null,
    });
    dispatch({
      type: COMMERCIAL_SME_POLICY_RESPONSE,
      payload: null,
    });
  }, [dispatch]);

  const routes = {
    publicLiability: 'publicliability',
    businessInterruption: 'business-interruption',
    electronicEquipment: 'electronic-equipment',
    cyberLiability: 'cyber-liability',
    employeeDishonesty: 'employeedishonesty',
  };

  const [isSubmitted, setSubmitted] = useState(false);
  const [coverageSelected, setCoverageSelected] = useState(false);

  const camelCaseToSpaceSeperated = (text) => {
    var result = text.replace(/([A-Z])/g, ' $1');
    return result.charAt(0).toUpperCase() + result.slice(1);
  };

  const renderRadioGroup = (key) => {
    return (
      <div className='radio-container' key={key}>
        <div className='radio-label'>
          {camelCaseToSpaceSeperated(key)}
          <img src={LiveHelp} className='live-help-icon' alt='live-help-icon' />
        </div>
        <NexusRadio
          name={key}
          value='Yes'
          checked={state.coverage[key] === 'Yes'}
          onInput={(event) =>
            setState({
              ...state,
              coverage: { ...state.coverage, [key]: event.target.value },
            })
          }
        >
          Yes
        </NexusRadio>
        <NexusRadio
          name={key}
          value='No'
          checked={state.coverage[key] === 'No'}
          onInput={(event) =>
            setState({
              ...state,
              coverage: { ...state.coverage, [key]: event.target.value },
            })
          }
        >
          No
        </NexusRadio>
      </div>
    );
  };

  const submitDetails = async () => {
    let coverages = [];
    Object.entries(state.coverage).forEach(([key, value]) => {
      if (value == 'Yes') {
        coverages.push(routes[key]);
      }
    });

    if (coverages.length == 0) {
      coverages = ['publicliability', 'business-interruption', 'electronic-equipment', 'employeedishonesty'];
    }

    coverages.push('coverage-summery');
    console.log(coverages);
    // if (state.policyStartDate) {
    dispatch({
      type: SUBMIT_COVERAGE_DETAILS,
      payload: {
        ...state,
        coverages,
        policyStartDate: state.policyStartDate,
        coverage: state.coverage,
      },
    });
    // }
    history.push(coverages[0]);
  };

  const selectCoverage = () => {
    let coverages = [
      'publicliability',
      'business-interruption',
      'electronic-equipment',
      'employeedishonesty',
      'coverage-summery',
    ];
    dispatch({
      type: SUBMIT_COVERAGE_DETAILS,
      payload: { ...state, coverages },
    });
    // history.push(coverages[0]);
  };

  return (
    <div className='container telstra-container coverage-selection-screen'>
      <Header />
      <div className='content-wrapper'>
        <div className='page-title'>Choose your policy start date and the coverage options for your business</div>
        <div className='form-wrapper'>
          <div className='feilds-container'>
            <label className='label select-policy-label'>Select your policy start date</label>
            <NexusFormField className='card-input'>
              <NexusLabel>Policy Start Date (MM/DD/YYYY)</NexusLabel>
              <NexusInput
                hideClearButton={true}
                type='date'
                value={state.policyStartDate}
                onInput={(event) => setState({ ...state, policyStartDate: event.target.value })}
                required
              />
              {isSubmitted && !state.policyStartDate && (
                <NexusErrorMessage>Please select policy start date.</NexusErrorMessage>
              )}
            </NexusFormField>
            <label className='label coverage-opt-label'>Select your coverage options.</label>
            <div className='coverage-card'>
              <div className='d-flex flex-row '>
                <div className='flex-fill'>
                  <div className='blue-text'>Recommended</div>
                  <div className='coverage-card-title'>Telco Business Bundle</div>
                </div>
                <div>
                  {' '}
                  <img src={Business} className='business-icon' alt='business-icon' />
                </div>
              </div>

              <div className='coverage-card-sub-title'>
                Our recommended bundle for our Telstra franchisees! This bundle includes all of the typical coverages
                you’ll need for your Telco business.
              </div>
              <div className='coverage-card-content d-flex flex-row '>
                <div className='flex-fill'>
                  <div className='coverage-opt d-flex align-items-end'>
                    <img src={GreyTick} className='tick-icon' alt='tick-icon' />
                    Public Liability
                  </div>
                  <div className='coverage-opt d-flex align-items-end'>
                    <img src={GreyTick} className='tick-icon' alt='tick-icon' />
                    Business Interruption
                  </div>
                  <div className='coverage-opt d-flex align-items-end'>
                    <img src={GreyTick} className='tick-icon' alt='tick-icon' />
                    Electronic Equipment
                  </div>
                  <div className='coverage-opt d-flex align-items-end'>
                    <img src={GreyTick} className='tick-icon' alt='tick-icon' />
                    Employee Dishonesty
                  </div>
                </div>
                <div className='d-flex align-items-end select-container'>
                  <button
                    type='button'
                    className='app-button'
                    onClick={() => {
                      selectCoverage();
                    }}
                  >
                    Select
                  </button>
                </div>
              </div>
            </div>
            <div className='coverage-title'>
              Don’t want the bundle? You can select the individual coverages you want below
            </div>
            <div>{Object.keys(state.coverage).map((coverage) => renderRadioGroup(coverage))}</div>
            {isSubmitted && !coverageSelected && (
              <NexusErrorMessage>Please select atleast one coverage.</NexusErrorMessage>
            )}
          </div>
        </div>
        <button
          type='button'
          className='app-button'
          onClick={() => {
            submitDetails();
          }}
        >
          CONTINUE
        </button>
      </div>
    </div>
  );
};

export default CoverageSelection;
