import React, { useState } from 'react';
import {
  NexusFormField, 
  NexusLabel,
  NexusInput,
  NexusRadio, 
  NexusErrorMessage 
} from '@nexus/react';
import Header from '../sharedComponents/Header';
import '../style/InsuredInfo.scss';
import { useDispatch } from "react-redux";
import {
  SUBMIT_INUSRED_DETAILS,
} from '../../../constants/ActionTypes';
import Service from "../sharedComponents/Service";

const InsuredInfo = (props) => {
  const [state, setState] = useState({
    firstName: '',
    lastName: '',
    phone: '',
    email: '',
    isAustralian: ''
  });

  const [isSubmitted, setSubmitted] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const service = new Service();
  const { history } = props;
  const dispatch = useDispatch();

  const validatePhone = () => {
    return new Promise((resolve) => {
      service
        .phoneNumberValidator(state.phone)
        .then((res) => res.json())
        .then((data) => {
          if (data.data) {
            setPhoneError(false);
            resolve(true);
          } else if (data.error) {
            setPhoneError(true);
            resolve(false);
          }
        })
        .catch((err) => {
          setPhoneError(true);
          resolve(false);
        });
    });
  }

  const validateEmail = () => {
    return new Promise((resolve) => {
      service
        .emailValidator(state.email)
        .then((res) => res.json())
        .then((data) => {
          if (data.data) {
            setEmailError(false);
            resolve(true);
          } else if (data.error) {
            setEmailError(true);
            resolve(false);
          }
        })
        .catch((err) => {
          setEmailError(true);
          resolve(false);
        });
    });
  }

  const submitDetails = async () => {
     setSubmitted(true);
     if (state.email && state.phone && state.isAustralian && state.firstName && state.lastName) {
       setIsLoading(true);
       const isPhoneValidated = await validatePhone();
       const isEmailValidated = await validateEmail();
       Promise.all([isPhoneValidated, isEmailValidated]).then((values) => {
         setIsLoading(false);
         if (isPhoneValidated && isEmailValidated) {
          dispatch({
            type: SUBMIT_INUSRED_DETAILS,
            payload: state,
          });
          history.push('coverage-selection');
         }
       });
     }
     // history.push('coverage-selection');
  };

 

  return (
    <div className='container telstra-container insured-screen'>
      <Header />
      <div className='content-wrapper'>
        <div className='page-title feild-spacing'>Now we need some information about you, the insured</div>
        <div className='form-wrapper'>
          <NexusFormField className='app-input feild-spacing'>
            <NexusLabel>First Name</NexusLabel>
            <NexusInput type="text" value={state.firstName} onInput={(event) => setState({ ...state, firstName: event.target.value })} required />
            {isSubmitted && !state.firstName && <NexusErrorMessage >Please enter first name.</NexusErrorMessage >}
          </NexusFormField>
          <NexusFormField className='app-input feild-spacing'>
            <NexusLabel>Last Name</NexusLabel>
            <NexusInput type="text" value={state.lastName} onInput={(event) => setState({ ...state, lastName: event.target.value })} required />
            {isSubmitted && !state.lastName && <NexusErrorMessage >Please enter last name.</NexusErrorMessage >}
          </NexusFormField>
          <NexusFormField className='app-input feild-spacing'>
            <NexusLabel>Phone Number</NexusLabel>
            <NexusInput type="tel" value={state.phone} onInput={(event) => setState({ ...state, phone: event.target.value })} required />
            {isSubmitted && (!state.phone || phoneError) && <NexusErrorMessage >Please enter a valid phone number.</NexusErrorMessage >}
          </NexusFormField>
          <NexusFormField className='app-input feild-spacing'>
            <NexusLabel>Email Address</NexusLabel>
            <NexusInput type="email" value={state.email} onInput={(event) => setState({ ...state, email: event.target.value })} required />
            {isSubmitted && (!state.email || emailError) && <NexusErrorMessage >Please enter a valid email.</NexusErrorMessage >}
          </NexusFormField>
          <div className="feild-spacing">
            <p className='radio-title'>Are you an Australian Resident?</p>
                <NexusRadio
                  name="isAustralian"
                  value='Yes'
                  checked={state.isAustralian === "Yes"}
                  onInput={(event) =>
                    setState({ ...state, isAustralian: event.target.value })
                  }>
                  Yes
                </NexusRadio>
                <NexusRadio name="isAustralian" value='No' checked={state.isAustralian === "No"} onInput={(event) =>
                  setState({ ...state, isAustralian: event.target.value })
                }>
                  No
                </NexusRadio>
              {isSubmitted && state.isAustralian === '' && <NexusErrorMessage >Please select an option</NexusErrorMessage >}
          </div>
        </div>
        <button type="button" className='app-button' onClick={() => { submitDetails() }}>
          CONTINUE
        </button>
      </div>
    </div>
  );
};

export default InsuredInfo;
