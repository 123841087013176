import { Component } from "react";
import ToggleButton from '../sharedComponents/ToggleButton';
import { connect } from "react-redux";
import Service from "../sharedComponents/Service";
import Header from '../sharedComponents/Header';
import '../style/QuoteSummary.scss';
import { tick } from '../../../assets/icons/';
import { SUBMIT_QUOTE_SUMMERY_DETAILS } from "../../../constants/ActionTypes";

class QuoteSummary extends Component {
  service = new Service();

  constructor(props) {
    super(props);
    this.state = {
      premiumType: 'Monthly',
    };
  }

  nextPage() {
    const { dispatch, history } = this.props;
    dispatch({
      type: SUBMIT_QUOTE_SUMMERY_DETAILS,
      payload: {
        insuredBillPlanType: 'Monthly'
      },
    });
    history.push('payment');
  }

  roundOffThisNumber = (num) => {
    return Math.round(num * 100) / 100;
  }
  handleChildCallback = (childData) =>{
    this.setState({premiumType: childData})
}

  render() {
    const { selectedCoverage, commercialSMESubmissionResponse } = this.props;
    const { premiumType } = this.state;
    const routes = {
      publicliability: 'Public Liability',
      'business-interruption': 'Business Interruption',
      'electronic-equipment': 'Electronic Equipment',
      'cyber-liability': 'Cyber Liability',
      employeedishonesty: 'Employee Dishonesty'
  }
    return (
      <>
        <Header />
        <div className="container telstra-container gx-5 pb-5">
          <div className='row'>
            <div className="col-md-12 col-lg-3"> </div>
            <div className="col-md-12 col-lg-6">
              <div className='row'>
                <p className="page-title"> Great news, you got a quote! Review your quote details below </p>
              </div>

              <div className="row mt-3 mb-1 text-center">
                <div className="col" >
                  <p className="" style={{ fontSize: '15px', lineHeight: '20px', color: ' #6E6E6E' }}> Billing frequency </p>
                </div>
              </div>

              <div className='d-flex justify-content-center'><ToggleButton premiumType={premiumType} parentCallback = {this.handleChildCallback} A/></div>

              <div className='row mt-5 mb-5 justify-content-center'>
                <div className="roundDot">
                  {premiumType === 'Monthly' ? (
                    <>
                    <span> $ {this.roundOffThisNumber(commercialSMESubmissionResponse?.premiumTotalMonthly) || ''} </span> <br />
                  <p style={{ marginTop: '-45px', color: '#000000' }}> PER MONTH  </p>
                    </>
                  ):(
                    <>
                    <span> $ {this.roundOffThisNumber(commercialSMESubmissionResponse?.premiumTotal) || ''} </span> <br />
                  <p style={{ marginTop: '-45px', color: '#000000' }}> PER YEAR  </p>
                    </>
                  ) }
                  
                </div>
              </div>

              <div className="mt-3">
                {selectedCoverage.map((data,index) =>
                  index !==selectedCoverage.length-1 && <div className={`row ${index!==selectedCoverage.length-2 ? "mb-5" : "" }`} key={data}>
                    <div className="col-1"></div>
                    <div className="col-2">
                      <img src={tick} alt="" style={{ marginTop: '-6px' }}>
                      </img>
                    </div>
                    <div className="col-8 statement">{routes[data]}</div>
                  </div>
                )}
              </div>

              <div className='row px-2 mb-4'>
                <p className="" style={{ fontSize: '15px', lineHeight: '20px', color: ' #6E6E6E', marginTop: '60px' }}> Happy with your quote? Click the button below to proceed to payment. </p>
              </div>

              <div className='row px-3'>
                <button type="button" className='app-button' onClick={() => this.nextPage()}>
                  PAY {" "}
                  {premiumType === 'Monthly' ? (`${this.roundOffThisNumber(commercialSMESubmissionResponse?.premiumTotalMonthly) || ''}/ MO`)
                  : (`${this.roundOffThisNumber(commercialSMESubmissionResponse?.premiumTotal)|| ''}/ Year`)
                }
                </button>
              </div>
            </div>
          </div>
        </div>

      </>
    );
  }
}
const mapStateToProps = (state) => {
  const { quote } = state;
  return {
    selectedCoverage: quote.selectedCoverage.coverages,
    commercialSMESubmissionResponse: quote.commercialSMESubmissionResponse
  };
};

export default connect(mapStateToProps)(QuoteSummary);
