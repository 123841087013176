
export const RESET_ALL_STATES = 'RESET_ALL_STATES';
export const GET_TOKEN_SUCCESS = 'GET_TOKEN_SUCCESS';
export const SUBMIT_INUSRED_DETAILS = 'SUBMIT_INUSRED_DETAILS';
export const SUBMIT_BUSINESS_INFO = 'SUBMIT_BUSINESS_INFO';
export const SUBMIT_BUSINESS_INTERRUPTION = 'SUBMIT_BUSINESS_INTERRUPTION';
export const SUBMIT_COVERAGE_DETAILS = 'SUBMIT_COVERAGE_DETAILS';
export const SUBMIT_PUBLIC_LIABILITY = 'SUBMIT_PUBLIC_LIABILITY';
export const SUBMIT_ELECTRONIC_EQUIPMENTS = 'SUBMIT_ELECTRONIC_EQUIPMENTS';
export const SUBMIT_CYBER_LIABILITY = 'SUBMIT_CYBER_LIABILITY';
export const SUBMIT_EMPLOYEE_DISHONESTY = 'SUBMIT_EMPLOYEE_DISHONESTY';
export const SUBMIT_VALIDATED_ADDRESS = 'SUBMIT_VALIDATED_ADDRESS';
export const COMMERCIAL_SME_SUBMISSION_RESPONSE = 'COMMERCIAL_SME_SUBMISSION_RESPONSE';
export const COMMERCIAL_SME_POLICY_RESPONSE = 'COMMERCIAL_SME_POLICY_RESPONSE';
export const SUBMIT_QUOTE_SUMMERY_DETAILS = 'SUBMIT_QUOTE_SUMMERY_DETAILS';
