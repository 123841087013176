import React, { useState } from "react";
import {
  NexusFormField,
  NexusLabel,
  NexusInput,
  NexusSelect,
  NexusOption,
} from "@nexus/react";

import { connect } from "react-redux";
import Header from "../sharedComponents/Header";
import { SUBMIT_BUSINESS_INTERRUPTION } from "../../../constants/ActionTypes";
import { INDEMNITY_PERIOD } from "../utils/Constants";
import "../style/BusinessInterruption.scss";
import { useSelector } from "react-redux";

const BusinessInterruption = (props) => {
  const {quote} = useSelector(state => state);
  const [state, setState] = useState({
    additionalIncreasedCost: "",
    claimsPreparationsCost: "",
    outstandingAccountsReceivable: "",
    indemnityPeriod: "",
  });
  // eslint-disable-next-line no-unused-vars
  const [error, setError] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [isLoading, setIsLoading] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [isSubmitted, setSubmitted] = useState(false);
  const { dispatch, history } = props;

  const submitDetails = async () => {
    setSubmitted(true);
    // if (
    //   state.additionalIncreasedCost &&
    //   state.claimsPreparationsCost &&
    //   state.outstandingAccountsReceivable &&
    //   state.indemnityPeriod
    // ) {
      setIsLoading(true);
      setTimeout(() => {
        setIsLoading(false);
        dispatch({
          type: SUBMIT_BUSINESS_INTERRUPTION,
          payload: state,
        });
        // history.push('quotesummary');
      }, 3000);
    // } else {
    //   setError('Please enter all the details');
    // }

    // history.push("electronic-equipment");
    history.push(quote.selectedCoverage?.coverages[quote.selectedCoverage?.coverages.indexOf('business-interruption')+1]);
  };

  return (
    <>
      <Header />
      <div className="container telstra-container gx-5 pb-5">
        <div className="row">
          <div className="col-md-12 col-lg-3"></div>
          <div className="col-md-12 col-lg-6">
            <div className="row">
              <div className="col-md-12">
                <div className="page-title header-spacing">
                  Business Interruption Coverage Questions
                </div>
              </div>
              <div className="col-md-12">
                <div className="bi-label-style">
                  Input the amount for Additional Increased Cost of Working
                </div>
                <NexusFormField className="app-input feild-spacing bi-label-spacing">
                  <NexusLabel>Enter the amount</NexusLabel>
                  <NexusInput
                    type="text"
                    value={state.additionalIncreasedCost}
                    onInput={(event) =>
                      setState({
                        ...state,
                        additionalIncreasedCost: event.target.value,
                      })
                    }
                  />
                </NexusFormField>
              </div>
              <div className="col-md-12">
                <div className="bi-label-style">
                  Input the amount of Claims Preparations Cost
                </div>
                <NexusFormField className="app-input feild-spacing bi-label-spacing">
                  <NexusLabel>Enter the amount</NexusLabel>
                  <NexusInput
                    type="text"
                    value={state.claimsPreparationsCost}
                    onInput={(event) =>
                      setState({
                        ...state,
                        claimsPreparationsCost: event.target.value,
                      })
                    }
                  />
                </NexusFormField>
              </div>
              <div className="col-md-12">
                <div className="bi-label-style">
                  Input the amount of Outstanding Accounts Receivable you
                  anticipate
                </div>
                <NexusFormField className="app-input feild-spacing bi-label-spacing">
                  <NexusLabel>Enter the amount</NexusLabel>
                  <NexusInput
                    type="text"
                    value={state.outstandingAccountsReceivable}
                    onInput={(event) =>
                      setState({
                        ...state,
                        outstandingAccountsReceivable: event.target.value,
                      })
                    }
                  />
                </NexusFormField>
              </div>
              <div className="col-md-12">
                <div className="bi-label-style">
                  Please select your desired Indemnity Period
                </div>
                <NexusFormField className="app-input bi-label-spacing">
                  <NexusLabel>Please select</NexusLabel>
                  <NexusSelect
                    value={state.indemnityPeriod}
                    name="indemnityPeriodOptions"
                    onInput={(event) =>
                      setState({
                        ...state,
                        indemnityPeriod: event.target.value,
                      })
                    }
                  >
                    {INDEMNITY_PERIOD.map((item) => (
                      <option value={item.value} key={item.value}>
                        {item.value}
                      </option>
                    ))}
                  </NexusSelect>
                </NexusFormField>
              </div>
              <div className="col-md-12 bi-submit-botton">
                <button
                  type="button"
                  className="app-button"
                  onClick={() => {
                    submitDetails();
                  }}
                >
                  CONTINUE
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default connect()(BusinessInterruption);
