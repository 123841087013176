import { Component } from 'react';
import { connect } from 'react-redux';
import { NexusFormField, NexusInput, NexusLabel } from '@nexus/react';
import Header from '../sharedComponents/Header';
import { SUBMIT_EMPLOYEE_DISHONESTY } from '../../../constants/ActionTypes';
class EmpDishonesty extends Component {
  constructor() {
    super();
    this.state = {
      workForceStaffing: '',
      coverageAmount: '',
    };
  }

  nextPage() {
    this.props.dispatch({
      type: SUBMIT_EMPLOYEE_DISHONESTY,
      payload: this.state,
    });
    this.props.history.push('coverage-summery');
  }
  render() {
    return (
      <>
        <Header />
        <div className='container telstra-container gx-5 pb-5'>
          <div className='row'>
            <div className='col-md-12 col-lg-3'> </div>
            <div className='col-md-12 col-lg-6'>
              <div className='row'>
                <p className='page-title'> Employee Dishonesty Coverage Questions </p>
              </div>

              <div className='row mt-3'>
                <p className='' style={{ fontSize: '16px', lineHeight: '22px', color: '#000000' }}>
                  {' '}
                  Select the amount of Employee Dishonesty coverage you require{' '}
                </p>
              </div>

              <div className='row'>
                <div className='col-md-12'>
                  <NexusFormField>
                    <NexusLabel>Coverage amount</NexusLabel>
                    <NexusInput
                      value={this.state.coverageAmount}
                      onInput={(event) => this.setState({ coverageAmount: event.target.value })}
                    />
                  </NexusFormField>
                </div>
              </div>

              <div className='row mt-5'>
                <div className='col-md-12'>
                  <p className='' style={{ fontSize: '16px', lineHeight: '22px', color: '#000000' }}>
                    {' '}
                    How many employees work for your business?{' '}
                  </p>
                </div>
              </div>

              <div className='row'>
                <div className='col-md-12'>
                  <NexusFormField>
                    <NexusLabel>Number of employees</NexusLabel>
                    <NexusInput
                      value={this.state.workForceStaffing}
                      onInput={(event) => this.setState({ workForceStaffing: event.target.value })}
                    />
                  </NexusFormField>
                </div>
              </div>

              <div className='row gx-0' style={{ marginTop: '14rem' }}>
                <div className='col-md-12'>
                  <button type='button' className='app-button' onClick={() => this.nextPage()}>
                    CONTINUE
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default connect()(EmpDishonesty);
