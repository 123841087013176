import React, { useState } from "react";
import {
  NexusFormField,
  NexusLabel,
  NexusInput,
} from "@nexus/react";
import { connect } from "react-redux";
import Header from "../sharedComponents/Header";
import {
  SUBMIT_BUSINESS_INFO,
  SUBMIT_VALIDATED_ADDRESS,
} from "../../../constants/ActionTypes";
import Service from "../sharedComponents/Service";

const service = new Service();

const BusinessInfo = (props) => {
  const [state, setState] = useState({
    businessName: "",
    businessAddress: "",
    businessAddress2: "",
    businessCity: "",
    businessState: "",
    businessPostalCode: "",
    noOfEnmployees: "",
    estimatedAnnualRevenue: "",
    businessCountry: "",
  });
  // eslint-disable-next-line no-unused-vars
  const [error, setError] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [isLoading, setIsLoading] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [isSubmitted, setSubmitted] = useState(false);
  const { dispatch, history } = props;

  const submitDetails = async () => {
    setSubmitted(true);
    // if (
    //   state.businessName &&
    //   state.businessAddress &&
    //   state.businessCity &&
    //   state.businessState &&
    //   state.businessPostalCode &&
    //   state.businessCountry &&
    //   state.noOfEnmployees &&
    //   state.estimatedAnnualRevenue
    // ) {
    setIsLoading(true);
    let reqBody = {
      address1: state.businessAddress,
      address2: "",
      locality: state.businessCity,
      country: "AU",
      administrativeArea: state.businessState,
      postalCode: state.businessPostalCode,
    };
    // commment 
    dispatch({
      type: SUBMIT_BUSINESS_INFO,
      payload: state,
    });
    // till here
    service
      .addressValidation(reqBody)
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.data) {          
          dispatch({
            type: SUBMIT_BUSINESS_INFO,
            payload: state,
          });
          dispatch({
            type: SUBMIT_VALIDATED_ADDRESS,
            payload: data.data,
          });
          history.push("insured-info");
        } else if (data.error) {
         // setError('Please enter all the details');
        }
      })
      .catch((err) => {
       // setError('Please enter all the details', err);
      });
      // history.push("insured-info");
  };

  return (
      <div className="container telstra-container gx-5 pb-5">
      <Header />
        <div className="row">
          <div className="col-md-12 col-lg-3"></div>
          <div className="col-md-12 col-lg-6">
            <div className="nexus-row">
              <div className="nexus-col-xs-4">
                <div className="page-title header-spacing">
                  Let’s start with gathering some information about your
                  business
                </div>
              </div>
              <div className="nexus-col-xs-4">
                <NexusFormField className="app-input feild-spacing">
                  <NexusLabel>Business/Trade Name</NexusLabel>
                  <NexusInput
                    type="text"
                    value={state.businessName}
                    onInput={(event) =>
                      setState({ ...state, businessName: event.target.value })
                    }
                  />
                </NexusFormField>
              </div>
              <div className="nexus-col-xs-4">
                <NexusFormField className="app-input feild-spacing">
                  <NexusLabel>Business Address</NexusLabel>
                  <NexusInput
                    type="text"
                    value={state.businessAddress}
                    onInput={(event) =>
                      setState({
                        ...state,
                        businessAddress: event.target.value,
                      })
                    }
                  />
                </NexusFormField>
              </div>
              <div className="nexus-col-xs-4">
                <NexusFormField className="app-input feild-spacing">
                  <NexusLabel>Business City</NexusLabel>
                  <NexusInput
                    type="text"
                    value={state.businessCity}
                    onInput={(event) =>
                      setState({ ...state, businessCity: event.target.value })
                    }
                  />
                </NexusFormField>
              </div>
              <div className="nexus-col-xs-4">
                <NexusFormField className="app-input feild-spacing">
                  <NexusLabel>Business State</NexusLabel>
                  <NexusInput
                    type="text"
                    value={state.businessState}
                    onInput={(event) =>
                      setState({ ...state, businessState: event.target.value })
                    }
                  />
                </NexusFormField>
              </div>
              <div className="nexus-col-xs-4">
                <NexusFormField className="app-input feild-spacing">
                  <NexusLabel>Business Postal Code</NexusLabel>
                  <NexusInput
                    type="text"
                    value={state.businessPostalCode}
                    onInput={(event) =>
                      setState({
                        ...state,
                        businessPostalCode: event.target.value,
                      })
                    }
                  />
                </NexusFormField>
              </div>
              <div className="nexus-col-xs-4">
                <NexusFormField className="app-input feild-spacing">
                  <NexusLabel>Number of Employees</NexusLabel>
                  <NexusInput
                    type="text"
                    value={state.noOfEnmployees}
                    onInput={(event) =>
                      setState({ ...state, noOfEnmployees: event.target.value })
                    }
                  />
                </NexusFormField>
              </div>
              <div className="nexus-col-xs-4">
                <NexusFormField className="app-input">
                  <NexusLabel>Estimated Annual Revenue</NexusLabel>
                  <NexusInput
                    type="text"
                    value={state.estimatedAnnualRevenue}
                    onInput={(event) =>
                      setState({
                        ...state,
                        estimatedAnnualRevenue: event.target.value,
                      })
                    }
                  />
                </NexusFormField>
              </div>
              <div className="nexus-col-xs-4" style={{ marginTop: "40px" }}>
                <button
                  type="button"
                  className="app-button"
                  onClick={() => {
                    submitDetails();
                  }}
                >
                  CONTINUE
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
  );
};

export default connect()(BusinessInfo);
