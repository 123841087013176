import React, { useState } from 'react';
import { NexusFormField, NexusLabel, NexusRadio, NexusSelect, NexusOption } from '@nexus/react';

import { connect } from 'react-redux';
import Header from '../sharedComponents/Header';
import { SUBMIT_CYBER_LIABILITY } from '../../../constants/ActionTypes';
import { CYBER_LIABILITY_COST, numberWithCommas } from '../utils/Constants';
import '../style/BusinessInterruption.scss';
import { useSelector } from 'react-redux';

const CyberLiability = (props) => {
  const [state, setState] = useState({
    personallyIdentifiableInformation: '',
    coverageAmount: '',
    OSWithAntiVirus: '',
    deviceProtected: '',
    multiFactorAuthentication: '',
    revenueDerivedFrom: '',
    outsourceFromNetwork: '',
    coverForSocialEngineering: '',
    numberOfFinancialTransactions: '',
  });
  const { quote } = useSelector((state) => state);
  // eslint-disable-next-line no-unused-vars
  const [error, setError] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [isLoading, setIsLoading] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [isSubmitted, setSubmitted] = useState(false);
  const { dispatch, history } = props;

  const submitDetails = async () => {
    setSubmitted(true);
    if (
      state.personallyIdentifiableInformation &&
      state.OSWithAntiVirus &&
      state.deviceProtected &&
      state.multiFactorAuthentication &&
      state.revenueDerivedFrom &&
      state.outsourceFromNetwork &&
      state.coverForSocialEngineering &&
      state.numberOfFinancialTransactions &&
      state.coverageAmount
    ) {
      console.log('CyberLiability', state);
      setIsLoading(true);
      setTimeout(() => {
        setIsLoading(false);
        dispatch({
          type: SUBMIT_CYBER_LIABILITY,
          payload: state,
        });
        // history.push('quotesummary');
      }, 3000);
    } else {
      //setError('Please enter all the details');
    }

    // history.push("employeedishonesty");
    history.push(quote.selectedCoverage?.coverages[quote.selectedCoverage?.coverages.indexOf('cyber-liability') + 1]);
  };

  return (
    <>
      <Header />
      <div className='container telstra-container gx-5 pb-5'>
        <div className='nexus-row'>
          <div className='nexus-col-md-12 nexus-col-lg-3'></div>
          <div className='nexus-col-md-12 nexus-col-lg-6'>
            <div className='nexus-row'>
              <div className='nexus-col-md-12'>
                <div className='page-title header-spacing'>Cyber Liability Coverage Questions</div>
              </div>
              <div className='nexus-col-md-12'>
                <div className='bi-label-style'>Select the amount of Cyber Liability coverage you require</div>
                <NexusFormField className='app-input feild-spacing bi-label-spacing'>
                  <NexusLabel>Please select</NexusLabel>
                  <NexusSelect
                    value={state.coverageAmount}
                    name='indemnityPeriodOptions'
                    onInput={(selectedValue) =>
                      setState({
                        ...state,
                        coverageAmount: selectedValue.target.value,
                      })
                    }
                  >
                    {CYBER_LIABILITY_COST.map((item) => (
                      <option value={item.key} key={item.key}>
                        {numberWithCommas(item.value)}
                      </option>
                    ))}
                  </NexusSelect>
                </NexusFormField>
              </div>

              <div className='nexus-col-md-12 nexus-mb-2'>
                <div className='bi-label-style nexus-mb-2'>
                  Are you aware of any matter that is reasonably likely to give rise to any loss or claim under such
                  insurance, or have you suffered any loss or any claim including but not limited to regulatory,
                  governmental, or adminsitration action brought against you, or any investigation or information
                  request concerning any handling of personally identifiable information?
                </div>
                <NexusRadio
                  name='personallyIdentifiableInformation'
                  value='Yes'
                  checked={state.personallyIdentifiableInformation === 'Yes'}
                  onInput={(event) =>
                    setState({
                      ...state,
                      personallyIdentifiableInformation: event.target.value,
                    })
                  }
                >
                  Yes
                </NexusRadio>
                <NexusRadio
                  name='personallyIdentifiableInformation'
                  value='No'
                  checked={state.personallyIdentifiableInformation === 'No'}
                  onInput={(event) =>
                    setState({
                      ...state,
                      personallyIdentifiableInformation: event.target.value,
                    })
                  }
                >
                  No
                </NexusRadio>
              </div>

              <div className='nexus-col-md-12 nexus-mb-2'>
                <div className='bi-label-style nexus-mb-2'>
                  Do you use operating systems with embedded firewalls and anti-virus protection software, or run
                  commercially licensed separate firewall or anti-virus protection software?
                </div>
                <NexusRadio
                  name='OSWithAntiVirus'
                  value='Yes'
                  checked={state.OSWithAntiVirus === 'Yes'}
                  onInput={(event) =>
                    setState({
                      ...state,
                      OSWithAntiVirus: event.target.value,
                    })
                  }
                >
                  Yes
                </NexusRadio>
                <NexusRadio
                  name='OSWithAntiVirus'
                  value='No'
                  checked={state.OSWithAntiVirus === 'No'}
                  onInput={(event) =>
                    setState({
                      ...state,
                      OSWithAntiVirus: event.target.value,
                    })
                  }
                >
                  No
                </NexusRadio>
              </div>

              <div className='nexus-col-md-12 nexus-mb-2'>
                <div className='bi-label-style nexus-mb-2'>
                  Are all mobile devices (such as laptops and smartphones) password protected?
                </div>
                <NexusRadio
                  name='deviceProtected'
                  value='Yes'
                  checked={state.deviceProtected === 'Yes'}
                  onInput={(event) =>
                    setState({
                      ...state,
                      deviceProtected: event.target.value,
                    })
                  }
                >
                  Yes
                </NexusRadio>
                <NexusRadio
                  name='deviceProtected'
                  value='No'
                  checked={state.deviceProtected === 'No'}
                  onInput={(event) =>
                    setState({
                      ...state,
                      deviceProtected: event.target.value,
                    })
                  }
                >
                  No
                </NexusRadio>
              </div>

              <div className='nexus-col-md-12 nexus-mb-2'>
                <div className='bi-label-style nexus-mb-2'>
                  Is multi factor authentication required for any remote access to your systems?
                </div>
                <NexusRadio
                  name='multiFactorAuthentication'
                  value='Yes'
                  checked={state.multiFactorAuthentication === 'Yes'}
                  onInput={(event) =>
                    setState({
                      ...state,
                      multiFactorAuthentication: event.target.value,
                    })
                  }
                >
                  Yes
                </NexusRadio>
                <NexusRadio
                  name='multiFactorAuthentication'
                  value='No'
                  checked={state.multiFactorAuthentication === 'No'}
                  onInput={(event) =>
                    setState({
                      ...state,
                      multiFactorAuthentication: event.target.value,
                    })
                  }
                >
                  No
                </NexusRadio>
              </div>

              <div className='nexus-col-md-12 nexus-mb-2'>
                <div className='bi-label-style nexus-mb-2'>Is more than 25% of your revenue derived from the USA or Canada?</div>
                <NexusRadio
                  name='revenueDerivedFrom'
                  value='Yes'
                  checked={state.revenueDerivedFrom === 'Yes'}
                  onInput={(event) =>
                    setState({
                      ...state,
                      revenueDerivedFrom: event.target.value,
                    })
                  }
                >
                  Yes
                </NexusRadio>
                <NexusRadio
                  name='revenueDerivedFrom'
                  value='No'
                  checked={state.revenueDerivedFrom === 'No'}
                  onInput={(event) =>
                    setState({
                      ...state,
                      revenueDerivedFrom: event.target.value,
                    })
                  }
                >
                  No
                </NexusRadio>
              </div>

              <div className='nexus-col-md-12 nexus-mb-2'>
                <div className='bi-label-style nexus-mb-2'>Do you outsource any part of your network, including storage?</div>
                <NexusRadio
                  name='outsourceFromNetwork'
                  value='Yes'
                  checked={state.outsourceFromNetwork === 'Yes'}
                  onInput={(event) =>
                    setState({
                      ...state,
                      outsourceFromNetwork: event.target.value,
                    })
                  }
                >
                  Yes
                </NexusRadio>
                <NexusRadio
                  name='outsourceFromNetwork'
                  value='No'
                  checked={state.outsourceFromNetwork === 'No'}
                  onInput={(event) =>
                    setState({
                      ...state,
                      outsourceFromNetwork: event.target.value,
                    })
                  }
                >
                  No
                </NexusRadio>
              </div>

              <div className='nexus-col-md-12 nexus-mb-2'>
                <div className='bi-label-style nexus-mb-2'>Do you want cover for Social Engineering, Phishing or Cyber Fraud?</div>
                <NexusRadio
                  name='coverForSocialEngineering'
                  value='Yes'
                  checked={state.coverForSocialEngineering === 'Yes'}
                  onInput={(event) =>
                    setState({
                      ...state,
                      coverForSocialEngineering: event.target.value,
                    })
                  }
                >
                  Yes
                </NexusRadio>
                <NexusRadio
                  name='coverForSocialEngineering'
                  value='No'
                  checked={state.coverForSocialEngineering === 'No'}
                  onInput={(event) =>
                    setState({
                      ...state,
                      coverForSocialEngineering: event.target.value,
                    })
                  }
                >
                  No
                </NexusRadio>
              </div>

              <div className='nexus-col-md-12'>
                <div className='bi-label-style nexus-mb-2'>
                  Do you process, transmit or store more than 1,000,000 financial transactions or records containing an
                  individual’s personal information, per year?
                </div>
                <NexusRadio
                  name='numberOfFinancialTransactions'
                  value='Yes'
                  checked={state.numberOfFinancialTransactions === 'Yes'}
                  onInput={(event) =>
                    setState({
                      ...state,
                      numberOfFinancialTransactions: event.target.value,
                    })
                  }
                >
                  Yes
                </NexusRadio>
                <NexusRadio
                  name='numberOfFinancialTransactions'
                  value='No'
                  checked={state.numberOfFinancialTransactions === 'No'}
                  onInput={(event) =>
                    setState({
                      ...state,
                      numberOfFinancialTransactions: event.target.value,
                    })
                  }
                >
                  No
                </NexusRadio>
              </div>
              <div className='nexus-col-md-12 bi-submit-botton'>
                <button
                  type='button'
                  className='app-button'
                  onClick={() => {
                    submitDetails();
                  }}
                >
                  CONTINUE
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default connect()(CyberLiability);
