import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Welcome from './component/Welcome';
import BusinessInfo from './component/BusinessInfo';
import InsuredInfo from './component/InsuredInfo';
import CoverageSelection from './component/CoverageSelection';
import PublicLiability  from './component/PublicLiability';
import BusinessInterruption  from './component/BusinessInterruption';
import ElectronicEquipment from './component/ElectronicEquipment';
import CyberLiability from './component/CyberLiability';
 import EmpDishonesty from './component/EmpDishonesty';
import CoverageSummary from './component/CoverageSummary';
import QuoteSummary  from './component/QuoteSummary';
import Payment from './component/Payment';
import PolicySuccess from './component/PolicySuccess';

const Router = () => (
    <Switch>
      <Route path='/telstra/welcome' component={Welcome}/>
      <Route path='/telstra/business-info' component={BusinessInfo}/>
      <Route path='/telstra/insured-info' component={InsuredInfo}/>
      <Route path='/telstra/coverage-selection' component={CoverageSelection}/>
      <Route path='/telstra/publicliability' component={PublicLiability}/>
      <Route path='/telstra/business-interruption' component={BusinessInterruption}/>
      <Route path='/telstra/electronic-equipment' component={ElectronicEquipment}/>
      <Route path='/telstra/cyber-liability' component={CyberLiability}/>
      <Route path='/telstra/employeedishonesty' component={EmpDishonesty}/> 
      <Route path='/telstra/coverage-summery' component={CoverageSummary}/>
      <Route path='/telstra/quotesummary' component={QuoteSummary}/>
      <Route path='/telstra/payment' component={Payment}/>
      <Route path='/telstra/policysuccess' component={PolicySuccess}/>
    </Switch>
)

export default Router;