import { Component } from 'react';
import { NexusFormField, NexusRadio, NexusSelect, NexusOption, NexusLabel } from '@nexus/react';
import { connect } from 'react-redux';
import Service from '../sharedComponents/Service';
import Header from '../sharedComponents/Header';
import '../style/QuoteSummary.scss';
import { SUBMIT_PUBLIC_LIABILITY } from '../../../constants/ActionTypes';

class PublicLiability extends Component {
  service = new Service();
  coverageAmount = [
    {
      value: '5000000',
    },
    {
      value: '10000000',
    },
    {
      value: '20000000',
    },
  ];
  constructor() {
    super();
    this.state = {
      PublicLiability: null,
      workForceStaffing: null,
      coverageAmount: null,
    };
  }
  nextPage() {
    this.props.history.push(this.props.coverages[this.props.coverages.indexOf('publicliability') + 1]);
    this.props.dispatch({
      type: SUBMIT_PUBLIC_LIABILITY,
      payload: this.state,
    });
  }
  numberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  render() {
    const { coverageAmount, PublicLiability, workForceStaffing } = this.state;
    return (
      <>
        <Header />
        <div className='container telstra-container gx-5 pb-5'>
          <div className='row'>
            <div className='col-md-12 col-lg-3'> </div>
            <div className='col-md-12 col-lg-6'>
              <div className='row'>
                <p className='page-title'> Public Liability Coverage Questions </p>
              </div>

              <div className='row mt-3'>
                <p className='' style={{ fontSize: '16px', lineHeight: '22px', color: '#000000' }}>
                  {' '}
                  Select the amount of Public Liability coverage you require{' '}
                </p>
              </div>

              <div className='row'>
                <div className='col-md-12'>
                  <NexusFormField>
                    <NexusLabel>Please select...</NexusLabel>
                    <NexusSelect
                      value={coverageAmount}
                      name='billingOption'
                      onInput={(event) => this.setState({ coverageAmount: event.target.value })}
                    >
                      <option value='Please Select' disabled>Please Select</option>
                      <option value='5000000'>$ 5000000</option>
                      <option value='10000000'>$ 10000000</option>
                      <option value='20000000'>$ 20000000</option>
                    </NexusSelect>
                  </NexusFormField>
                </div>
              </div>

              <div className='row mt-5'>
                <div className='col-md-12'>
                  <p className='' style={{ fontSize: '16px', lineHeight: '22px', color: '#000000' }}>
                    {' '}
                    Would you like to note your landlord as an interested party on your Public Liability policy?{' '}
                  </p>
                </div>
              </div>

              <div className='row mt-2'>
                <div className='feild-spacing'>
                  <NexusRadio   
                    name='PublicLiability'
                    value='Yes'
                    checked={PublicLiability === 'Yes'}
                    onInput={(event) => this.setState({ PublicLiability: event.target.value })}
                  >
                    Yes
                  </NexusRadio>
                  <NexusRadio
                    name='PublicLiability'
                    value='No'
                    checked={PublicLiability === 'No'}
                    onInput={(event) => this.setState({ PublicLiability: event.target.value })}
                  >
                    No
                  </NexusRadio>
                </div>
              </div>

              <div className='row'>
                <div className='col-md-12'>
                  <p className='' style={{ fontSize: '16px', lineHeight: '22px', color: '#000000' }}>
                    {' '}
                    Does your business engage with or intend to engage with any of the following: non-clerical
                    contractors, subcontractors, or labor from workforce staffing firms?{' '}
                  </p>
                </div>
              </div>
              <div className='row mt-2 mb-5'>
                <div className='feild-spacing'>
                  <NexusRadio
                    name='workForceStaffing'
                    value='Yes'
                    checked={workForceStaffing === 'Yes'}
                    onInput={(event) => this.setState({ workForceStaffing: event.target.value })}
                  >
                    Yes
                  </NexusRadio>
                  <NexusRadio
                    name='workForceStaffing'
                    value='No'
                    checked={workForceStaffing === 'No'}
                    onInput={(event) => this.setState({ workForceStaffing: event.target.value })}
                  >
                    No
                  </NexusRadio>
                </div>
              </div>

              <div className='row gx-0'>
                <div className='col-md-12'>
                  <button type='button' className='app-button' onClick={() => this.nextPage()}>
                    CONTINUE
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = function (state) {
  return {
    coverages: state.quote.selectedCoverage?.coverages,
  };
};

export default connect(mapStateToProps)(PublicLiability);
