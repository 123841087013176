import React, { useState, useImperativeHandle, forwardRef } from "react";
import { NexusProgressBar } from '@nexus/react';
import useInterval from "./useInterval";
import "./Loader.scss";
const Loader = forwardRef((props, ref) => {
  const TEXT_START = "Building Your Quote";
  const TEXT_END = "Your Quote is Ready!";
  const [loading, setLoading] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  useImperativeHandle(ref, () => ({
    loadingCompleted() {
      setIsLoading(false);
    },
  }));

  useInterval(() => {
    if (isLoading && loading < 75) {
      setLoading(loading + 1);
    } else if (!isLoading && loading < 100) {
      setLoading(loading + 1);
      if (loading === 99) {
        setTimeout(() => {
          props.doneLoading();
        }, 1000);
      }
    }
  }, 100);

  return (
    <div className="loader-container">
      <div className="col-xs-12 center">
        <NexusProgressBar circle value={loading} />
      </div>

      <div className="col-xs-12 center loading-text-container mt-10">
        <div className="loading-text">
          {loading === 100 ? TEXT_END : TEXT_START}
        </div>
      </div>
    </div>
  );
});

export default Loader;
